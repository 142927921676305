<template>
    <div class="viewing-card-layout">
        <slot />
    </div>
</template>

<style lang="postcss" scoped>
    .viewing-card-layout {
        @apply bg-white;
        @apply dark:bg-slate-700;
        @apply grid;
        @apply rounded-3xl;
        @apply shadow;
        @apply overflow-hidden;
        grid-template-columns: 1fr;

        @media (min-width: theme('screens.lg')) {
            @apply grid-flow-col;
            grid-template-columns: 2fr 1fr 1fr;
            @apply auto-cols-card;
        }
    }
</style>
