export default {
  "components": {
    "DarkModeIcon": {
      "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable dark mode"])},
      "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable dark mode"])}
    }
  },
  "intro": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome ", _interpolate(_named("relatieNaam"))])},
    "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This is your personal portal from ", _interpolate(_named("brandingName")), "."])},
    "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find all your viewing appointments."])},
    "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you already visited? Then let us know what you think? You can do this quickly and easily via the review button. We would really appreciate this."])},
    "line1-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is je persoonlijke portaal van de VLIEG Advies Groep."])},
    "line2-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier vind je al je bezichtigingsafspraken overzichtelijk terug."])},
    "line3-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heb je al een bezichtiging gedaan? Wil je ons dan laten weten hoe dat is gegaan? Via de beoordelingsknop is dat VLIEGensvlug te doen."])},
    "line4-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als dank voor je tijd en gefundeerde mening bieden we je aan het einde van de korte vragenlijst een presentje aan."])}
  },
  "viewing": {
    "thumbnails": {
      "open-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More photos"])}
    },
    "filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All appointments"])},
      "withoutResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without response"])},
      "withResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With response"])}
    },
    "status": {
      "withoutResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without response"])},
      "withResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With response"])}
    },
    "route": {
      "toProperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Route"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hrs"])}
    },
    "nextAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next appointment"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures"])}
  },
  "locale": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "action": {
    "changeAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change appointment"])},
    "reviewViewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review viewing"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])}
  }
}