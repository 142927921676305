import { ref } from 'vue'

const darkModeEnabled = ref(false)

export function useDarkMode() {
    const toggleDarkMode = () => darkModeEnabled.value = !darkModeEnabled.value
    const enableDarkMode = () => darkModeEnabled.value = true
    const disableDarkMode = () => darkModeEnabled.value = false

    return {
        darkModeEnabled,
        toggleDarkMode,
        enableDarkMode,
        disableDarkMode,
    }
}
