import { ref } from 'vue'

const selectedViewingsFilter = ref('all')
const viewingsFilters = ref([
    'all',
    'without-response',
    'with-response',
])

export function useViewingsFilter() {
    return {
        selectedViewingsFilter,
        viewingsFilters,
    }
}
