import { Relatie } from '@/interface'

export function formatRelatieNaam(relatie: Relatie): string {
    if (relatie.roepnaam) {
        return relatie.roepnaam
    }

    return [relatie.titel?.toLowerCase(), relatie.tussenvoegsel, relatie.achternaam]
        .filter(value => value !== null && value !== '')
        .join(' ')
}

export function formatMedewerkerNaam(medewerker: Relatie): string {
    let voornaam = medewerker.roepnaam
    if (!voornaam) {
        voornaam = (medewerker.titel + ' ' + medewerker.initialen).trim()
    }

    return [voornaam, medewerker.tussenvoegsel, medewerker.achternaam]
        .filter(value => value !== null && value !== '')
        .join(' ')
}
