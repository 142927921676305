import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CompanyBranding from '@/views/CompanyBranding.vue'
import ViewingList from '@/views/Viewing/ViewingList.vue'
import BlankView from '@/views/BlankView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: BlankView,
    },
    {
        path: '/:linkId',
        name: 'company-style',
        component: CompanyBranding,
        props: true,
        children: [
            {
                path: '',
                name: 'viewing-list',
                component: ViewingList,
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
