import { format as dateFormat } from 'date-fns'
import { enGB, nl } from 'date-fns/locale'
import { useLocale } from '@/composables/locale'

const localeMap = new Map([
    ['en', enGB],
    ['nl', nl],
])

export function formatDate(date: Date, format = 'PPPp', locale: string | null = null) {
    const { currentLocale } = useLocale()

    return dateFormat(date, format, {
        locale: localeMap.get(locale ?? currentLocale.value),
    })
}
