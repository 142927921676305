export default {
  "components": {
    "DarkModeIcon": {
      "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donkere modus uitschakelen"])},
      "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donkere modus inschakelen"])}
    }
  },
  "intro": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welkom ", _interpolate(_named("relatieNaam"))])},
    "line1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit is jouw persoonlijke portaal van ", _interpolate(_named("brandingName")), "."])},
    "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier vind je al jouw bezichtigingsafspraken overzichtelijk terug."])},
    "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heb je al bezichtigd? Laat ons dan weten wat je ervan vindt? Via de beoordelingsknop kun je dat snel en eenvoudig doen. Wij zouden dit erg waarderen."])},
    "line1-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is je persoonlijke portaal van de VLIEG Advies Groep."])},
    "line2-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier vind je al je bezichtigingsafspraken overzichtelijk terug."])},
    "line3-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heb je al een bezichtiging gedaan? Wil je ons dan laten weten hoe dat is gegaan? Via de beoordelingsknop is dat VLIEGensvlug te doen."])},
    "line4-vlieg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als dank voor je tijd en gefundeerde mening bieden we je aan het einde van de korte vragenlijst een presentje aan."])}
  },
  "viewing": {
    "thumbnails": {
      "open-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer fotos"])}
    },
    "filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle afspraken"])},
      "withoutResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog niet beoordeeld"])},
      "withResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld"])}
    },
    "status": {
      "withoutResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog niet beoordeeld"])},
      "withResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeeld"])}
    },
    "route": {
      "toProperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk route"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uur"])}
    },
    "nextAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerstvolgende afspraak"])},
    "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen"])}
  },
  "locale": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "action": {
    "changeAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzig afspraak"])},
    "reviewViewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beoordeel bezichtiging"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw proberen"])}
  }
}