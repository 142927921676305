import { ref } from 'vue'

const currentLocale = ref('nl')
const availableLocales = ref([
    'nl',
    'en',
])

export function useLocale() {
    const setLocale = (value: string) => {
        if (availableLocales.value.includes(value) === false) {
            console.warn(`Attempted to set unknown locale "${value}"`)

            return
        }

        currentLocale.value = value
    }

    return { currentLocale, availableLocales, setLocale }
}
