import { reactive, ref } from 'vue'
import { Branding, LoadedData, Viewing } from '@/interface'
import client from '@/api/apiClient'

const error = ref<string|null>(null)
const loading = ref(true)
const branding = ref<Branding|null>(null)
const entries: LoadedData = []
const viewings = reactive<Viewing[]>([])

const loadInitialData = async(linkId: string) => {
    error.value = null
    loading.value = true

    try {
        for (const entry of await client.fetchInitialData(linkId)) {
            entries.push({
                agenda: await client.fetchAgenda(entry.agenda),
                medewerker: await client.fetchMedewerker(entry.medewerker),
                relatie: await client.fetchRelatie(entry.relatie),
                wonenObject: await client.fetchWonenObject(entry.wonenObject),
                form: entry.form,
                response: entry.response,
            })
        }

        for (const entry of entries) {
            const viewing: Viewing = {
                id: entry.agenda.id,
                date: new Date(entry.agenda.begintijd ?? ''),
                adres: {
                    straat: entry.wonenObject.adres.straat ?? '',
                    straat2: entry.wonenObject.adres.straat2 ?? '',
                    huisnummer: entry.wonenObject.adres.huisnummer ?? '',
                    huisnummertoevoeging: entry.wonenObject.adres.huisnummertoevoeging ?? '',
                    postcode: entry.wonenObject.adres.postcode ?? '',
                    plaats: entry.wonenObject.adres.plaats ?? '',
                    land: entry.wonenObject.adres.land ?? '',
                    provincie: '',
                },
                algemeen: {
                    status: 'status',
                    prijs: 'prijs',
                    prijstype: 'prijstype',
                    aantalKamers: entry.wonenObject.algemeen.aantalKamers,
                    aantalSlaapkamers: entry.wonenObject.algemeen.aantalSlaapkamers,
                    bouwjaar: entry.wonenObject.algemeen.bouwjaar,
                    bouwvorm: entry.wonenObject.algemeen.bouwvorm,
                    woonhuissoort: entry.wonenObject.algemeen.woonhuissoort,
                    woonhuistype: entry.wonenObject.algemeen.woonhuistype,
                    woonoppervlakte: entry.wonenObject.algemeen.woonoppervlakte,
                },
                media: Object
                    .values(entry.wonenObject.media)
                    .filter(v => ['image/jpeg'].includes(v.mimetype ?? '')),
                medewerker: entry.medewerker,
                relatie: entry.relatie,
                agenda: entry.agenda,
                form: entry.form,
                response: entry.response,
            }

            viewings.push(viewing)
        }

        branding.value = await client.fetchBranding(linkId)
        loading.value = false
    } catch (e) {
        console.error(e)
        error.value = 'Kon geen verbinding maken, probeer het later nogmaals.'
    }
}

export function useCustomerData() {
    return {
        error,
        loading,
        branding,
        entries,
        viewings,
        loadInitialData,
    }
}
