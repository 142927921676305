import { Branding, WonenObject, Agenda, InitialData, Relatie } from '@/interface'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

class ApiClient {
    private client: AxiosInstance

    constructor() {
        this.client = axios.create({
            baseURL: process.env.VUE_APP_API_BASEURL,
        })

        this.client.interceptors.request.use((request: AxiosRequestConfig) => this.onRequest(request))
    }

    private onRequest(request: AxiosRequestConfig): AxiosRequestConfig {
        if (request && request.headers) {
            request.headers.Accept = 'application/json'
        }

        return request
    }

    async fetchData<T>(url: string): Promise<T> {
        const response = await this.client.get(url)

        if (response.status !== 200) {
            throw new Error(response.statusText)
        }

        return response.data
    }

    async fetchInitialData(id: string): Promise<InitialData> {
        return this.fetchData<InitialData>(`/api/v1/service/customer_portal/${id}`)
    }

    async fetchBranding(id: string): Promise<Branding> {
        return this.fetchData<Branding>(`/api/v1/service/customer_portal/${id}/branding`)
    }

    async fetchAgenda(id: string): Promise<Agenda> {
        return this.fetchData<Agenda>('/api/v1/service/realworks/agenda/' + id)
    }

    async fetchMedewerker(id: string): Promise<Relatie> {
        return this.fetchData<Relatie>('/api/v1/service/realworks/medewerker/' + id)
    }

    async fetchRelatie(id: string): Promise<Relatie> {
        return this.fetchData<Relatie>('/api/v1/service/realworks/relatie/' + id)
    }

    async fetchWonenObject(id: string): Promise<WonenObject> {
        return this.fetchData<WonenObject>(`/api/v1/service/realworks/wonen_object/${id}`)
    }
}

export default new ApiClient()
